const demoData=`
BEZIERGROUP
BEZIERCURVE
48.77419267181136 2.2976118848944793 48.77417900923837 2.2976144532619003 48.774172952989886 2.297604187166172
48.77419219997918 2.2976619538303282 48.77421200608231 2.297667557025477
48.77422174128316 2.297624259609819 48.77422174128316 2.2976018468292807
48.77418078628858 2.297572302710762 48.77415795889999 2.2975768871431512
48.77414318821485 2.2976232408904025 48.774140502638204 2.2976476911951806
48.7740490961084 2.297031872835305 48.77407732567457 2.2969914624346757 48.77405522139392 2.2970734955480054
48.77412153331693 2.2968515127868443 48.77415455653443 2.2968406019783743 48.77411514172516 2.2969202104676754
48.774275137691774 2.2967988617653248
48.774279369210745 2.2968303239607906
48.774301796254576 2.296822618933959
48.774422074288964 2.297607372128823
48.7744000704497 2.2976137929852314
48.77440472510895 2.2976465393520016
48.774226578302546 2.2977094637429616
48.77422276993153 2.297686348660818 48.77420333267446 2.29769276036194 48.77422530523313 2.297696607382619
48.77416437924225 2.2976674118488347 48.77415360422921 2.297638238609551
CLOSED
BEZIERGROUP
BEZIERCURVE
48.77425486781223 2.2969197322518937 48.77423350040226 2.296923334766632 48.77425209797212 2.2969119268036877
48.7742242016227 2.2969641632712694 48.77422716931963 2.296986979195708
48.77426950844355 2.2969989875773535 48.77429364568482 2.2969905817093093
48.77429997676285 2.2969299393801634 48.77429918537791 2.296899318005046
48.77422558654817 2.2968386756747634 48.77417137658824 2.296844079446828
48.77415317469067 2.2970037909306598 48.77416108855999 2.2970578286502814
48.774263341798815 2.2971484628760948 48.77426367323193 2.297155472896492 48.774194095637085 2.2971454607800865
48.774264179403986 2.297164073773587 48.77430256157348 2.297172479640551 48.77426369122753 2.2971563384584215
48.7743476242575 2.297234322809402
48.774314781813814 2.297026577796771
48.77423129068566 2.2970433895328313 48.77419211707536 2.297014569415097
48.77421467158206 2.2969197031950443 48.774236434694075 2.2968944855920483
CLOSED
BEZIERGROUP
BEZIERCURVE
48.774376776251245 2.2974155466596358
48.77437140430925 2.2974155466596358 48.7743660323666 2.2974469875281613 48.774374014306375 2.2974157893852976
48.77432177777135 2.297500941611645 48.77430668515444 2.2975137508554155
48.774254244671994 2.2975168561264354 48.77425884920433 2.297540921975582 48.774286988004775 2.297525007463605
48.774240175264595 2.297577020751959 48.774243756568666 2.2975991458073395 48.77425552370809 2.2975556720143118
48.77424733787251 2.297620106385409
48.774261407277436 2.297614284002975 48.77428187185947 2.2976515472554127 48.77425373305775 2.2976170011143893
48.77433559134897 2.2976550406845604 48.77436628817091 2.297641066966321
48.77438240399937 2.297569645732551 48.77439058981386 2.297567316779265 48.774378311092846 2.2975929352652997
48.774399287239504 2.2975649878260356
CLOSED
BEZIERGROUP
BEZIERCURVE
48.774105918438295 2.29682318644322 48.774150664391044 2.2968062122495496 48.774095615090374 2.296904930584077
48.77428907847931 2.2967577951837086
48.77429359179939 2.2967902729950254
48.774315597005284 2.2967835949038147
48.774448432325386 2.2976297324941584
48.77442627361509 2.297637537942734
48.774431417602386 2.297669660365102
48.7742105034763 2.2977510791046143
48.77420701737606 2.297724744548759 48.77418822195142 2.29772714622527 48.77420939154163 2.2977421567026113
48.77414411160123 2.2976742831584147 48.77413619772909 2.2976760844149737 48.77415182762658 2.2977145112389223
48.77412782887774 2.2976787355513295
48.774025208035454 2.2970240507741835 48.774067599007 2.296956600690038 48.774037277726194 2.297108028361521
CLOSED
BEZIERGROUP
BEZIERCURVE
48.774138650554676 2.297026536611696 48.77414378842087 2.297059847292104 48.77411155999093 2.297032206514899
48.774162471568985 2.297189546324148
48.774135274300406 2.297201726865282 48.77412592756845 2.297203592994734
48.77409430290135 2.297211294828287 48.77408864566928 2.297181436770188 48.77411225847587 2.2971986051516637
48.7740662532849 2.2970449637961394
48.77409100849215 2.2970017307851833 48.77410268547288 2.297026536611668 48.774076529035625 2.297024410397114
CLOSED
BEZIERGROUP
BEZIERCURVE
48.774137530254166 2.2969858578395304 48.774134859410765 2.2970216566414194
48.77410414470188 2.2969791033485762 48.774106370406344 2.29694533089517
CLOSED
BEZIERGROUP
BEZIERCURVE
48.77413441427018 2.2972648183099125 48.77412862744228 2.2972979153154256
48.77410191899756 2.297252660226235 48.774107705828555 2.29722226501832
CLOSED
BEZIERGROUP
BEZIERCURVE
48.774239467137846 2.297536348556065 48.77423783367371 2.2975719300092976
48.7742057874643 2.2975304956393927 48.77420890344388 2.2974987553836
CLOSED
BEZIERGROUP
BEZIERCURVE
48.77417933271158 2.2972891787660785
48.77418590770921 2.297329640248961 48.77417728788208 2.297352795677 48.77418400223846 2.2973118084650253
48.774176775767785 2.297416660151555 48.774179744441994 2.297446963970856
48.77420892019347 2.2974881605798885 48.77419569610646 2.297500855422726 48.774195696105835 2.297474646714903
48.77419211742807 2.297549684205052 48.77417808369816 2.297545179583267 48.77418429092526 2.2975111901641014
48.77414963349844 2.2975644798991652 48.774145855183576 2.2975386807010807 48.77416123832023 2.297554242122146
48.77411029518538 2.297313566508734 48.774126487972296 2.297315614063365 48.774111644584565 2.2973278993965778
48.774140313246875 2.297300384103522
CLOSED
BEZIERGROUP
BEZIERCURVE
48.77416872465338 2.2971037372433045 48.77420462189849 2.297154844855129 48.774175586619464 2.297155814723311
48.77424982517098 2.2971696391650482
48.774252927397924 2.2971877958171945
48.77432589898436 2.2972346356582705 48.77434544444884 2.2972667563290656
48.77435470379326 2.297315555214373
48.77436171750543 2.2973153889527302
48.77436793416847 2.2973624050352135
48.77436207987208 2.297366059937422 48.77436007615461 2.297386977333337 48.77436568106066 2.2973638306479294
48.77434106471338 2.297449923384079 48.77432546319221 2.2974777898800767
48.77425558644967 2.2974863718697236 48.77421614383479 2.297467542749388
48.77421658701053 2.2973848291130707 48.77421968923994 2.297349860746408
48.77426400677902 2.2973337215000527 48.77428882458426 2.2973444809984187
48.774290154109195 2.2973781044275086 48.774289267759 2.297396933547816
48.774248496276414 2.2974097085331096 48.774253544452165 2.2974167402085186 48.774277523952634 2.2974218148855243
48.77427863155867 2.297424504759533 48.77429680615393 2.297427189840448
48.77431142650312 2.297351205683981 48.77430832427936 2.297304132882573
48.77425735851898 2.297278577158977 48.774243620084036 2.2972758872849965
48.77419930442204 2.297307494267102 48.77419508572143 2.2972833419697167 48.774215471806144 2.297282962108568
48.774192212345554 2.2972584050187095
48.774154985562035 2.297271854391056
48.77415476555382 2.2972442812604754 48.774153437921 2.2972338728589534
48.774147451566705 2.297219401840522 48.774160064077336 2.2972133582527476 48.77414944301759 2.2972294744867554
48.77418334882813 2.297205280001151
CLOSED
`;

export {demoData};